import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Hyperlink Blog" slug="/blog/" />
        <div className="container">
          {posts.filter(post => post.node.fields.slug.startsWith("/blog")).map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug}>
                <div>
                  <h4>
                    <Link to={node.fields.slug}>
                      {title}
                    </Link>
                  </h4>
                  <small>{node.frontmatter.date}</small>
                </div>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
}
`
